<template>
  <div>
    <div class="page_header">
      <div></div>
      <!-- <v-text-field
        placeholder="Поиск пользователя"
        height="48"
        hide-details
        outlined
        dense
        v-model="search"
        @change="getList"
        class="mx-4"
      >
      </v-text-field> -->
      <vue-add-button @onClick="onAdd"> Добавить </vue-add-button>
    </div>
    <v-tabs v-model="tab" class="mt-4 mb-3">
      <v-tab @click="goTab">Все</v-tab>
      <v-tab @click="goTab(1)">Активные</v-tab>
      <v-tab @click="goTab(0)">Не активные</v-tab>
    </v-tabs>
    <v-data-table
      :items="items.results"
      :server-items-length="items.count"
      :headers="headers"
      :loading="loading"
      :options.sync="options"
      @click:row="goPage"
      disable-sort
    >
      <template v-slot:[`item.contact_person`]="{ item }">
        {{ item.first_name }} {{ item.middle_name }} {{ item.last_name }}
      </template>
      <template v-slot:[`item.phone_number`]="{ item }">
        {{ item.phone_number | VMask("+# (###) ### ##-##") }}
      </template>
      <template v-slot:[`item.is_active`]="{ item }">
        {{ item.is_active ? "Активный" : "Не активный" }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn v-if="item.is_active" icon @click.stop="onEdit(item)">
          <img src="@/assets/images/ui/edit.svg" alt="" />
        </v-btn>
        <v-btn v-if="item.is_active" icon @click.stop="onDelete(item)">
          <img src="@/assets/images/ui/delete.svg" alt="" />
        </v-btn>
        <v-btn v-if="!item.is_active" icon @click.stop="onActivate(item)">
          <img src="@/assets/images/ui/plus.svg" alt="" />
        </v-btn>
      </template>
    </v-data-table>
    <vue-dialog
      :model="dialog"
      :options="dialogOptions"
      @closeDialog="closeDialog"
      @onSubmit="onSubmit"
    >
      <v-form ref="form" lazy-validation>
        <input-content :items="subInputs" :model="model" :dialog="dialog" />
      </v-form>
    </vue-dialog>
  </div>
</template>

<script>
import vueAddButton from "@/components/ui/vueAddButton.vue";
import VueDialog from "@/components/ui/vueDialog.vue";
import InputContent from "@/components/inputs/inputContent.vue";
import { usersService } from "@/services/users.service.js";
import pagination from "@/utils/pagination";

export default {
  mixins: [pagination],
  components: {
    vueAddButton,
    VueDialog,
    InputContent,
  },
  data: () => ({
    search: null,
    dialog: false,
    model: {},
    tab: 0,
    is_active: null,
    items: [],
    headers: [
      { text: "Контактное лицо", value: "contact_person" },
      { text: "Телефон", value: "phone_number" },
      { text: "Email", value: "email" },
      { text: "Статус", value: "is_active" },
      { text: "", value: "actions" },
    ],
    loading: false,
    subInputs: [
      {
        type: "text",
        label: "Имя",
        model: "first_name",
        rules: "required",
        cols: 6,
      },
      {
        type: "text",
        label: "Фамилия",
        model: "last_name",
        rules: "required",
        cols: 6,
      },
      {
        type: "text",
        label: "Отчество",
        model: "middle_name",
        rules: "required",
        cols: 6,
      },
      {
        type: "text",
        label: "Email",
        model: "email",
        rules: "email",
        cols: 6,
      },
      {
        type: "text",
        label: "Телефон",
        mask: "+7 (###) ### ##-##",
        model: "phone_number",
        rules: "required",
        cols: 12,
      },
      {
        type: "text",
        inputType: "password",
        label: "Пароль",
        model: "password1",
        rules: "required",
        cols: 12,
      },
      {
        type: "text",
        inputType: "password",
        label: "Повторите пароль",
        model: "password2",
        rules: "required",
        cols: 12,
      },
    ],
  }),
  computed: {
    dialogOptions() {
      return {
        width: "711",
        title: this.model.id ? "Изменить пользователя" : "Создать пользователя",
        button: "Сохранить",
      };
    },
  },
  methods: {
    async getList() {
      this.loading = true;
      let params = {
        page: this.options.page,
        size: this.options.itemsPerPage,
        is_active: this.is_active,
        // search: this.search,
      };
      this.items = await usersService.getUsers({
        ...params,
      });
      this.loading = false;
    },
    onAdd() {
      this.dialog = true;
      this.model = {};
    },
    goTab(value) {
      if (typeof value == "number") this.is_active = value;
      else this.is_active = null;
      this.getList();
    },
    goPage({ id }) {
      // this.$router.push(`/users/${id}/`);
    },
    async onEdit(item) {
      try {
        this.$loading(true);
        this.model = this.$copy(item);
        this.dialog = true;
      } catch (e) {
        console.error(e);
      } finally {
        this.$loading(false);
      }
    },
    async onDelete(item) {
      await usersService.deleteUser(item.id);
      this.$store.commit("ui/SET_NOTIFICATION", {
        show: true,
        message: "Успешно диактивировали пользователя!",
        color: "success",
      });
      this.getList();
    },
    async onActivate(item) {
      // await usersService.patchUser(item, item.id);
			await usersService.deleteUser(item.id);
      this.$store.commit("ui/SET_NOTIFICATION", {
        show: true,
        message: "Успешно активировали пользователя!",
        color: "success",
      });
      this.getList();
    },
    closeDialog() {
      this.model = {};
      this.$refs.form.reset();
      this.dialog = false;
    },
    async onSubmit() {
      if (!this.$refs.form.validate()) return;
      try {
        this.$loading(true);
        if (this.model.id) {
					await usersService.patchUser(this.model, this.model.id);
        } else await usersService.postUser(this.model);
      } catch (e) {
        console.error(e);
      } finally {
        this.$loading(false);
        this.closeDialog();
        this.getList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page_header {
  display: grid;
  grid-template-columns: 1fr 160px;
}

.sub_title {
  font-family: "Gilroy", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 12px;
  color: #52b1ba;
}
</style>