var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"page_header"},[_c('div'),_c('vue-add-button',{on:{"onClick":_vm.onAdd}},[_vm._v(" Добавить ")])],1),_c('v-tabs',{staticClass:"mt-4 mb-3",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{on:{"click":_vm.goTab}},[_vm._v("Все")]),_c('v-tab',{on:{"click":function($event){return _vm.goTab(1)}}},[_vm._v("Активные")]),_c('v-tab',{on:{"click":function($event){return _vm.goTab(0)}}},[_vm._v("Не активные")])],1),_c('v-data-table',{attrs:{"items":_vm.items.results,"server-items-length":_vm.items.count,"headers":_vm.headers,"loading":_vm.loading,"options":_vm.options,"disable-sort":""},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.goPage},scopedSlots:_vm._u([{key:"item.contact_person",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.middle_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item.phone_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("VMask")(item.phone_number,"+# (###) ### ##-##"))+" ")]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_active ? "Активный" : "Не активный")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.is_active)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onEdit(item)}}},[_c('img',{attrs:{"src":require("@/assets/images/ui/edit.svg"),"alt":""}})]):_vm._e(),(item.is_active)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onDelete(item)}}},[_c('img',{attrs:{"src":require("@/assets/images/ui/delete.svg"),"alt":""}})]):_vm._e(),(!item.is_active)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onActivate(item)}}},[_c('img',{attrs:{"src":require("@/assets/images/ui/plus.svg"),"alt":""}})]):_vm._e()]}}],null,true)}),_c('vue-dialog',{attrs:{"model":_vm.dialog,"options":_vm.dialogOptions},on:{"closeDialog":_vm.closeDialog,"onSubmit":_vm.onSubmit}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('input-content',{attrs:{"items":_vm.subInputs,"model":_vm.model,"dialog":_vm.dialog}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }