import axios from "axios";

export const usersService = {
	async getUsers(params) {
		return (await axios.get('/auth/admin-users/', {
			params
		})).data
	},
	async deleteUser(id) {
		return await axios.delete(`/auth/admin-users/${id}/`)
	},
	async patchUser(data, id) {
		return (await axios.patch(`/auth/admin-users/${id}/`, data)).data
	},
	async postUser(data) {
		return (await axios.post('/auth/admin-users/', data)).data
},
}